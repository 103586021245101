import './App.css';
import LoginPopup from './components/login-popup/LoginPopup';
import { useEffect, useState } from 'react';
import { useDirectus } from 'react-directus';
import Names from './components/names/Names';
import { Route, Routes } from 'react-router-dom';




function App() {
  const { directus } = useDirectus();

  const refresh = () => {
    directus.auth.refresh().then((response) => {
      console.log(response);
    }).catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {

    const expires_at = localStorage.getItem('auth_expires_at')

    const timestamp = parseInt(expires_at, 10);
    const now = new Date().getTime();


    if (timestamp < now) {
      console.log('refresh')
      refresh()

    } else {

      console.log('no refresh')
    }

  }, [])


  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Names />} />

        <Route path="/login" element={<LoginPopup />} />
      </Routes>

    </div>
  );
}

export default App;
