import React, { useEffect, useState } from 'react'
import './login-popup.scss'
import { useDirectus } from 'react-directus';
export default function LoginPopup() {
    const { directus } = useDirectus();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [me, setMe] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        const loginParams = { email: email, password: password, mode: "cookie" };


        directus.auth.login(loginParams).then(
            (response) => {
                console.log(response);
            }).catch((error) => {
                console.log(error.message === 'Invalid user OTP.');
                console.log(error);


            });
    }

    const refresh = () => {
        console.log(directus.auth)
        directus.auth.refresh().then((response) => {
            console.log(response);
        }).catch((error) => {
            console.log(error);
        });
    }

    const getMe = async () => {
        try {
            const newMe = await directus.users.me.read();
            setMe(newMe);
            console.log(newMe);


        } catch (error) {
            console.log(error);
        }

    }

    useEffect(() => {

        getMe();

    }, [])

    const logout = (e) => {
        directus.auth.logout().then((response) => {
            console.log(response);
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <div className="login-popup">
            <form onSubmit={e => { handleSubmit(e) }}>


                <div className="input-fields">
                    <div className="input-wrapper">
                        <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
                    </div>
                    <div className="input-wrapper">
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Passord" />
                    </div>

                </div>
                <div>{me?.first_name} {me?.last_name}</div>

                <button
                    type='submit'
                    value='Login'
                >Login</button>

                <a className="button" onClick={() => logout()} value='Login'
                >Logout</a>
            </form>
            {/* 
            <button onClick={() => refresh()}>Refresh</button>
*/}
        </div>
    )
}
