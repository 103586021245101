import React, { useEffect, useState, useReducer, Fragment } from 'react';
import { useDirectus } from 'react-directus';
import './names.scss';

const initialState = {
    step: 0,
    preise: [
        {
            name: 'Wheelpark',
            image: 'wheelpark.png',
            winner: null
        },
        {
            name: 'Marbachegg',
            image: 'marbachegg.png',
            winner: null
        },
        {
            name: 'Roggenstock',
            image: 'roggenstock.png',
            winner: null
        },
        {
            name: 'Bikewelt',
            image: 'bikewelt.png',
            winner: null
        },
        {
            name: 'Trübsee',
            image: 'truebsee.png',
            winner: null
        },
        {
            name: 'Ausrüstung 2',
            image: 'ausruestung.png',
            winner: null
        },
        {
            name: 'Ausrüstung 1',
            image: 'ausruestung.png',
            winner: null
        },
        {
            name: 'Dirtbike',
            image: 'dirtbike.png',
            winner: null
        },
    ]
};
function reducer(state, action) {
    switch (action.type) {
        case 'NEXT_STEP':
            if (state.step === state.preise.length - 1) {
                return state;
            }
            return {
                ...state,
                step: state.step + 1,
            };
        case 'SET_WINNER':
            const updatedPreise = [...state.preise]; // Create a copy of the preise array
            updatedPreise[state.step] = {
                ...updatedPreise[state.step],
                winner: action.payload,
            };
            return {
                ...state,
                preise: updatedPreise,
            };
        default:
            return state;
    }
}

export default function Names(props) {
    const { directus } = useDirectus();
    const [data, setData] = useState([]);
    const [selectedData, setSelectedData] = useState(null);
    const [state, dispatch] = useReducer(reducer, initialState);



    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === ' ') {
                handleButtonClick();
            }
            if (event.key === 'n') {
                dispatch({ type: 'NEXT_STEP' });
                setSelectedData(null);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [data]);

    const handleButtonClick = () => {
        console.log(state);
        if (state.step === state.preise.length - 1) return;

        const numCalls = Math.floor(Math.random() * 21) + 20;
        let drawWinnerCount = 0;

        for (let i = 0; i < numCalls; i++) {
            setTimeout(() => {
                const { person, index } = drawWinner();

                drawWinnerCount++;
                if (drawWinnerCount === numCalls) {
                    console.log(person)
                    console.log(data)
                    dispatch({ type: 'SET_WINNER', payload: person });
                    createEntry(JSON.stringify(person), state.preise[state.step].name);
                    const filteredData = removeItemAtIndex(data, index);
                    setData(filteredData);
                }
            }, i * 100);
        }
    }

    const removeItemAtIndex = (arr, index) => {
        return [...arr.slice(0, index), ...arr.slice(index + 1)]
    }



    const drawWinner = () => {
        const randomIndex = Math.floor(Math.random() * data.length);
        console.log(data.length);
        setSelectedData(data[randomIndex]);

        return { person: data[randomIndex], index: randomIndex }
    }

    const getDataOnLoad = async () => {
        try {
            const items = await directus.items('wettbewerb').readByQuery({
                limit: 10000,
                fields: 'firstname,lastname,email,plz',
            });

            console.log(items.data.length)

            const filteredData = items.data.filter((item) => {
                return item.plz !== undefined && item.plz !== null && item.plz !== '';
            });

            const distinctData = filteredData.reduce((acc, current) => {
                const exists = acc.some(item => item.email === current.email);

                if (!exists) {
                    acc.push(current);
                }

                return acc;
            }, []);
            console.log(distinctData.length)

            setData(distinctData);

        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getDataOnLoad();
    }, []);

    const createEntry = async (data, prize) => {
        console.log(data);
        try {
            const newItem = await directus.items('aufloesung').createOne({
                text: data,
                prize: prize
            });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="names">
            {state.step < state.preise.length ?
                <Fragment>
                    <div className="names__image">
                        <img src={`/${state.preise[state.step].image}`} />
                    </div>
                    <div className="names__container">
                        <div className="names__title">
                            Dieser Preis geht an:
                        </div>
                        <div className="names__name">
                            {selectedData?.plz !== undefined ? selectedData?.firstname + ' ' + selectedData?.lastname : '?'}
                        </div>
                    </div>
                </Fragment> : null}
        </div >
    )
}
